import { UserAlertHeader, UserAlertModal } from "@iventis/components";
import { useHistory } from "@iventis/subscriptions";

import React, { FunctionComponent, useEffect, useState } from "react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { css } from "@emotion/react";
import { styled, media } from "@iventis/styles";

import { UpgradeControls, UpgradeTextContents, UpgradeType } from "./upgrade-components";
import sharedMapImage from "./assets/feature-sharedWorkspace.png";
import importImage from "./assets/feature-import.png";
import pdfExportImage from "./assets/feature-pdf-export.png";
import capacityImage from "./assets/feature-analysis-capacity.png";
import flowImage from "./assets/feature-analysis-flow.png";
import layer3DTypesImage from "./assets/feature-layer3DTypes.png";
import iconAndModelUploadsImage from "./assets/feature-iconAndModelUploads.png";
import imagesOnModelsGif from "./assets/feature-imagesOnModels.gif";

/**
 * Modal which when opened, informs the user of a piece of functionality they are missing out on if they stay on their current plan.
 * Provides buttons to either view their plan and upgrade, or contact us for more information
 */
export const UpgradeSubscriptionAlert: FunctionComponent = () => {
    const params = new URLSearchParams(window.location.search);
    const translate = useIventisTranslate();

    const { isOpen, close } = useUpgradePlanAlert();

    const upgradeType = params.get("upgrade-plan") as UpgradeType;

    const [modalContent, setModalContent] = useState<UpgradeType | null>(null);

    useEffect(() => {
        if (upgradeType != null) setModalContent(upgradeType);
    }, [upgradeType]);

    return (
        <UserAlertModal
            maxWidth="md"
            open={isOpen}
            close={close}
            TransitionProps={{
                onExited: () => {
                    setModalContent(null);
                },
            }}
        >
            {getFeatureSnapshot(modalContent)}
            <UserAlertHeader>{translate(Content.subscriptionplan2.limits.overlay.title[modalContent] ?? Content.settings2.upgrade_plan_warnings.upgrade_plan)}</UserAlertHeader>
            <UpgradeTextContents upgradeType={modalContent} />
            <UpgradeControls />
        </UserAlertModal>
    );
};

/**
 * Use this anywhere you want to open an upgrade plan modal
 */
export const useUpgradePlanAlert = () => {
    const history = useHistory();
    const isOpen = history.location.search.includes("upgrade-plan");
    const open = (type: UpgradeType) => {
        const params = new URLSearchParams(window.location.search);
        params.set("upgrade-plan", type);
        history.push({ search: params.toString() });
    };
    const close = () => {
        const params = new URLSearchParams(window.location.search);
        params.delete("upgrade-plan");
        history.push({ search: params.toString() });
    };

    return {
        /** Is the upgrade plan modal open? */
        isOpen,
        /**
         * Function to open the upgrade plan modal
         * @param upgradeType Type of feature you want to display in the upgrade plan modal
         */
        open,
        /** Close the upgarde plan modal */
        close,
    } as const;
};

/** Returns a snapshot of the feature to display on the upgrade plan modal */
const getFeatureSnapshot = (upgradeType: UpgradeType) => {
    switch (upgradeType) {
        case "sharedWorkspace":
            return <ShareMapSnapshot src={sharedMapImage} />;
        case "importingLayers":
            return <ShareMapSnapshot src={importImage} />;
        case "pdfExports":
            return <ShareMapSnapshot src={pdfExportImage} />;
        case "FlowRate":
            return <AnalysisSnapshot src={flowImage} />;
        case "Capacity":
            return <AnalysisSnapshot src={capacityImage} />;
        case "layer3DTypes":
            return <Layer3DTypesSnapshot src={layer3DTypesImage} />;
        case "iconAndModelUploads":
            return <IconAndModelUploadsSnapshot src={iconAndModelUploadsImage} />;
        case "imagesOnModels":
            return <ImagesOnModelsSnapshot src={imagesOnModelsGif} />;
        default:
            return <></>;
    }
};

/** Mixin for the border of the feature snapshots */
const imageBorderMixin = css`
    border-radius: 4px;
    /* Card shadows */
    box-shadow: 0px 8px 20px 0px rgba(81, 82, 101, 0.26);
`;

const ShareMapSnapshot = styled.img`
    ${imageBorderMixin}
    max-width: 80%;
    height: auto;
`;

const AnalysisSnapshot = styled.img`
    ${imageBorderMixin}
    max-width: 45%;
    ${media.extraSmall} {
        max-width: 60%;
    }
`;

const Layer3DTypesSnapshot = styled.img`
    ${imageBorderMixin}
    max-width: 40%;
    height: auto;
    ${media.extraSmall} {
        max-width: 55%;
    }
`;

const IconAndModelUploadsSnapshot = styled.img`
    ${imageBorderMixin}
    max-width: 40%;
    height: auto;
    ${media.extraSmall} {
        max-width: 50%;
    }
`;

const ImagesOnModelsSnapshot = styled.img`
    ${imageBorderMixin}
    max-width: 80%;
    height: auto;
    ${media.extraSmall} {
        max-width: 95%;
    }
`;
