/* eslint no-console: ["error", { allow: ["error"] }] */
import { createAsyncThunk } from "@reduxjs/toolkit";
import GeoJSON from "geojson";
import { AxiosError, AxiosResponse } from "axios";
import { NodeType } from "@iventis/domain-model/model/nodeType";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { aggregatePagedResponse } from "@iventis/api-helpers/src/api-helpers";
import { UpdateMultipleMapObjects } from "@iventis/domain-model/model/updateMultipleMapObjects";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { Map } from "@iventis/domain-model/model/map";
import { DataField } from "@iventis/domain-model/model/dataField";
import { TypedFeature } from "@iventis/map-engine/src/types/internal";
import { MapboxglStyle } from "@iventis/map-engine/src/types/store-schema";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import { Sitemap } from "@iventis/domain-model/model/sitemap";
import { MapSitemapConfig } from "@iventis/domain-model/model/mapSitemapConfig";
import { Asset } from "@iventis/domain-model/model/asset";
import { Style } from "mapbox-gl";
import { SavedMapView } from "@iventis/domain-model/model/savedMapView";
import { GeometryType } from "@iventis/domain-model/model/geometryType";
import { MapComment } from "@iventis/domain-model/model/mapComment";
import qs from "qs";
import { IventisFilterOperator } from "@iventis/domain-model/model/iventisFilterOperator";
import { ObjectUpdate } from "@iventis/map-engine/src/types/events-store";
import {
    convertDateDatafieldValuesToUTC,
    convertDateDatafieldValuesToLocal,
    uuidOnlyObjectEntries,
    parseDataFieldValuesWithRepeatedTimeRange,
    getAssetSignature,
} from "@iventis/utilities";
import { convertMapObjectToLocalGeoJson } from "@iventis/map-engine/src/utilities/state-helpers";
import { SitemapStyle } from "@iventis/map-engine/src/types/sitemap-style";
import { parseISO } from "date-fns";
import { getAssetsByType } from "@iventis/api-helpers";
import { CreateMapRequestPayload, GetBasemapRequestPayload, DomainLayer, MapGlobalState } from "@iventis/map-engine/src/state/map.state.types";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import { FilterFormat } from "@iventis/types";
import { RouteWaypoint } from "@iventis/domain-model/model/routeWaypoint";
import { mappingApi, assetsApi, cdnAssets } from "@iventis/api/src/api";
import { ResponseError } from "@iventis/api/src/axios.types";
import { DEFAULT_COMMENT_ICON_VALUE, commentsLayerId } from "@iventis/map-engine";
import { incrementMonitoringCount, layerCreationMonitoringMetric, MetricName, MetricTagName } from "@iventis/observability-and-monitoring";
import { LocalGeoJsonObject, MapCommentProperties, MapObjectProperties } from "@iventis/map-types";
import { map } from "./map.slice";
// eslint-disable-next-line iventis/no-root-store-dependencies
import { RootState } from "./root.store";
import { SidebarOrderUpdate } from "../sidebar-order-helpers";
import { deleteLayerDataField, postLayerDataField } from "../services/data-field-service";
import { patchLayers, postLayer } from "../services/layer-service";
import { routeWaypointService } from "../services/route-waypoint-service";

export const getBackgroundMaps = createAsyncThunk<Asset[], { signal?: AbortSignal }>("Basemap/getBackgroundMaps", async ({ signal }) => {
    const response = await getAssetsByType([AssetType.MapBackground], assetsApi, signal);
    return response;
});

export const getBasemap = createAsyncThunk<MapboxglStyle, GetBasemapRequestPayload & { signal?: AbortSignal }>(
    "Basemap/getBasemap",
    async ({ id, type, signal }, { rejectWithValue }) => {
        try {
            const basemapUrl = await assetsApi.get<Asset>(`/assets/${id}`, { signal });
            const basemapResponse = await cdnAssets.get(getAssetSignature(basemapUrl.data.assetUrl, basemapUrl.data.authoritySignature), { signal });
            return { style: { name: basemapUrl.data.name, ...basemapResponse.data }, type };
        } catch (err) {
            const error: AxiosError<ResponseError> = err;
            return rejectWithValue(error.response.data);
        }
    }
);

export const createMapRequest = createAsyncThunk<Map, CreateMapRequestPayload>("map/createMapRequest", async (map, { rejectWithValue }) => {
    try {
        const response = await mappingApi.post<Map>("/maps", { type: NodeType.Map, ...map });
        return response.data;
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        console.error("Error", error);
        return rejectWithValue(error.response.data);
    }
});

export const createLayerRequest = createAsyncThunk<MapLayer, MapLayer, { getState; state: { mapReducer: MapGlobalState } }>(
    "map/createLayerRequest",
    async (layer, { rejectWithValue, getState }) => {
        try {
            const response = await postLayer(layer, getState().mapReducer.mapModule.layers.value);
            layerCreationMonitoringMetric(layer.styleType, {
                [MetricTagName.LAYER_ID]: response.id,
                [MetricTagName.LAYER_NAME]: response.name,
                [MetricTagName.MAP_ID]: getState().mapReducer.mapModule.mapId,
            });
            return response;
        } catch (err) {
            const error: AxiosError<ResponseError> = err;
            console.error("Error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const getMapDetails = createAsyncThunk("Map/getMapDetails", async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
        const response = await mappingApi.get(`/maps/${id}`);
        return response.data;
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        return rejectWithValue(error.response.data);
    }
});

export const updateMap = createAsyncThunk("Map/updateMap", async (options: { map: Partial<Map>; skipSave?: boolean }, { rejectWithValue }) => {
    try {
        if (options.skipSave) {
            return { map: options.map };
        }
        const response = await mappingApi.patch<Map>(`/maps/${options.map.id}`, options.map);
        return { map: response.data };
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        // eslint-disable-next-line no-console
        console.error("Error", error);
        return rejectWithValue(error.response.data);
    }
});

export const patchMapLayer = createAsyncThunk<OptionalExceptFor<DomainLayer, "id">, OptionalExceptFor<DomainLayer, "id">, { state: RootState }>(
    "Map/patchMapLayer",
    async (mapLayer, { rejectWithValue, getState }) => {
        try {
            const { mapId } = getState().mapReducer.mapModule;
            await mappingApi.patch(`/maps/${mapId}/layers/${mapLayer.id}`, mapLayer);
            return mapLayer;
        } catch (err) {
            const error: AxiosError<ResponseError> = err;
            // eslint-disable-next-line no-console
            console.error("Error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

export type MapLayerPatchType = Partial<MapLayer> & Pick<MapLayer, "id">;

export const patchMapLayers = createAsyncThunk<DomainLayer[], MapLayerPatchType[], { state: RootState }>(
    "Map/patchMapLayers",
    async (mapLayers: MapLayerPatchType[], { rejectWithValue, getState }) => {
        try {
            const { mapId } = getState().mapReducer.mapModule;
            await patchLayers(mapId, mapLayers);
            return getState().mapReducer.mapModule.layers.value.reduce<DomainLayer[]>((cum, lyr) => {
                const layer = mapLayers.find(({ id }) => lyr.id === id);
                return layer ? [...cum, { ...lyr, ...layer }] : cum;
            }, []);
        } catch (err) {
            const error: AxiosError<ResponseError> = err;
            // eslint-disable-next-line no-console
            console.error("Error", error);
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateLayer = createAsyncThunk<MapLayer, MapLayer>("Map/updateLayer", async (mapLayer: MapLayer, { rejectWithValue }) => {
    try {
        const response = await mappingApi.put<MapLayer>(`/maps/${mapLayer.mapId}/layers/${mapLayer.id}`, mapLayer);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const createLayerDataFieldThunk = createAsyncThunk<DataField, { dataField: DataField; layerId: string }, { state: RootState }>(
    "Map/createLayerDataField",
    async (request, { rejectWithValue, getState }) => {
        try {
            let { order } = request.dataField;
            if (order == null || order === 0) {
                const existingLayerDataFields = getState().mapReducer.mapModule.layers.value.find((l) => l.id === request.layerId)?.dataFields;
                order = existingLayerDataFields?.length + 1;
            }
            incrementMonitoringCount(MetricName.CREATE_DATAFIELD, {
                includeUserTags: true,
                tags: {
                    [MetricTagName.MAP_ID]: getState().mapReducer.mapModule.mapId,
                    [MetricTagName.LAYER_ID]: request.layerId,
                    [MetricTagName.DATA_FIELD_ID]: request.dataField.id,
                    [MetricTagName.DATA_FIELD_TYPE]: request.dataField.type,
                    [MetricTagName.PROJECT_DATA_FIELD]: request.dataField.projectId != null,
                },
            });
            const response = await postLayerDataField({ ...request, dataField: { ...request.dataField, order } });
            return response;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const updateLayerDataFieldThunk = createAsyncThunk<DataField, { dataField: DataField; layerId: string }>(
    "Map/updateLayerDataField",
    async (request, { rejectWithValue }) => {
        try {
            const response = await mappingApi.patch<DataField>(`/layers/${request.layerId}/data-fields/${request.dataField.id}`, request.dataField);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const deleteLayerDataFieldThunk = createAsyncThunk<string, { dataFieldId: string; layerId: string }, { state: RootState }>(
    "Map/deleteLayerDataField",
    async (request, { rejectWithValue, getState }) => {
        try {
            const response = await deleteLayerDataField(request);
            incrementMonitoringCount(MetricName.DELETE_DATAFIELD, {
                includeUserTags: true,
                tags: {
                    [MetricTagName.MAP_ID]: getState().mapReducer.mapModule.mapId,
                    [MetricTagName.LAYER_ID]: request.layerId,
                    [MetricTagName.DATA_FIELD_ID]: request.dataFieldId,
                },
            });
            return response;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export interface GetGeoJsonResponse {
    feature: TypedFeature;
    objectId: string;
    layerId: string;
    waypoints?: RouteWaypoint[];
}

export const getGeoJson = createAsyncThunk<GetGeoJsonResponse[], { objects: { layerId: string; objectId: string }[]; mapId: string; layers: DomainLayer[] }>(
    "map/getGeoJson",
    async ({ objects, mapId, layers }, { rejectWithValue }) => {
        try {
            const objectsWithGeoJson = await Promise.all<GetGeoJsonResponse>(
                objects.map((object) =>
                    mappingApi.get<TypedFeature>(`maps/${mapId}/map_objects/${object.objectId}/geojson`).then((response) => {
                        const filteredLayers = layers.find((l) => l.id === object.layerId);
                        return {
                            feature: {
                                type: "Feature",
                                properties: convertDateDatafieldValuesToLocal({ ...response.data.properties, layerid: object.layerId }, filteredLayers?.dataFields),
                                geometry: response.data.geometry,
                            } as TypedFeature,
                            objectId: object.objectId,
                            layerId: object.layerId,
                        };
                    })
                )
            );
            // Assign their waypoints if they are routes
            for (let i = 0; i < objectsWithGeoJson.length; i += 1) {
                const object = objectsWithGeoJson[i];
                if (object.feature.properties.modeOfTransport != null) {
                    object.waypoints = await routeWaypointService.getRouteWaypoints(object.objectId, mapId);
                }
            }

            return objectsWithGeoJson;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

// eslint-disable-next-line consistent-return
export const createObjects = createAsyncThunk<ObjectUpdate[], ObjectUpdate[], { state: RootState }>("map/createObject", async (objects, { rejectWithValue, getState }) => {
    try {
        const { mapId, layers } = getState().mapReducer.mapModule;
        const mapObjects = objects.map((object) => {
            const filteredLayers = layers?.value?.find((l) => l.id === object.layerId);
            return {
                id: object.objectId,
                layerId: object.layerId,
                geoJsonFeature: { type: "Feature", geometry: object.geometry, properties: object.properties },
                dataFieldValues: convertDateDatafieldValuesToUTC(uuidOnlyObjectEntries(object.properties), filteredLayers?.dataFields),
                fixedShape: object.properties.fixedShape,
                level: object.properties.level,
                modeOfTransport: object.properties.modeOfTransport,
            } as MapObject;
        });
        await mappingApi.post<MapObject[]>(`/maps/${mapId}/map_objects`, mapObjects);

        // Update the waypoints if any of the objects are routes
        objects.forEach(async (update) => {
            if (update.waypoints != null) {
                await routeWaypointService.putRouteWaypoints(update.objectId, mapId, update.waypoints);
            }
        });
        return objects;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const restoreObjects = createAsyncThunk<MapObject[], ObjectUpdate[], { state: RootState }>(
    "map/restoreObjects",
    async (objects: ObjectUpdate[], { rejectWithValue, getState }) => {
        try {
            const { mapId, layers } = getState().mapReducer.mapModule;
            const mapObjects = objects.map((object) => {
                const filteredLayers = layers?.value?.find((l) => l.id === object.layerId);
                return {
                    id: object.objectId,
                    layerId: object.layerId,
                    geoJsonFeature: {
                        type: object.geometry.type,
                        coordinates: object.geometry.coordinates,
                        rotation: object.properties.rotation,
                        properties: object.properties,
                    },
                    dataFieldValues: convertDateDatafieldValuesToUTC(uuidOnlyObjectEntries(object.properties), filteredLayers?.dataFields),
                    fixedShape: object.properties.fixedShape,
                    level: object.properties.level,
                    modeOfTransport: object.properties.modeOfTransport,
                } as MapObject;
            });

            await mappingApi.put<string[]>(
                `/maps/${mapId}/map_objects/restore`,
                mapObjects.map((o) => o.id)
            );

            // Update the waypoints if any of the objects are routes
            objects.forEach(async (update) => {
                if (update.waypoints != null) {
                    await routeWaypointService.putRouteWaypoints(update.objectId, mapId, update.waypoints);
                }
            });
            return mapObjects;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const deleteObjects = createAsyncThunk<string[], { objectIds: string[]; modifyStore: boolean }, { getState; state: { mapReducer: MapGlobalState } }>(
    "map/deleteObjects",
    async ({ objectIds }, { rejectWithValue, dispatch, getState }) => {
        try {
            const { mapId } = getState().mapReducer.mapModule;
            dispatch(map.actions.addObjectChangesSaving(objectIds));
            // Construct a filter for mapObjectIds
            const filters = [
                {
                    $type: "IventisFilterGuidArray",
                    fieldName: "mapObjectIds",
                    operator: IventisFilterOperator.In,
                    value: objectIds,
                },
            ];
            await mappingApi.delete(`/maps/${mapId}/map_objects`, { data: filters });
            dispatch(map.actions.removeObjectChangesSaving(objectIds));
            return objectIds;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response.data);
        }
    }
);

export const deleteObjectsByLayerIds = createAsyncThunk<string[], { layerIds: string[] }, { getState; state: { mapReducer: MapGlobalState } }>(
    "map/deleteObjectsByLayerId",
    async ({ layerIds }, { rejectWithValue, getState }) => {
        try {
            const { mapId } = getState().mapReducer.mapModule;
            // Construct a filter for layerIds
            const filters = [
                {
                    $type: "IventisFilterGuidArray",
                    fieldName: "layerIds",
                    operator: IventisFilterOperator.In,
                    value: layerIds,
                },
            ];
            await mappingApi.delete(`/maps/${mapId}/map_objects`, { data: filters });
            return layerIds;
        } catch (err) {
            console.error(err);
            return rejectWithValue(err.response.data);
        }
    }
);

export const patchMapObjectsDataFieldValues = createAsyncThunk<
    MapObject[],
    { mapObjects: OptionalExceptFor<MapObject, "id" | "layerId">[]; signal?: AbortSignal; from?: "data-table" | undefined; fulfilledNotification?: boolean },
    { state: RootState }
>("map/patchMapObjectsDataFieldValues", async ({ mapObjects, signal }, { rejectWithValue, getState }) => {
    try {
        if (mapObjects?.length === 0 || mapObjects === undefined) {
            return [];
        }
        const { mapId, layers } = getState().mapReducer.mapModule;

        // Convert map object dates to UTC
        const mapObjectsPatch = mapObjects.map((object) => {
            const layersFiltered = layers?.value?.find((l) => l.id === object.layerId);
            // If repeated time range values are present they need to be converted, if value is not repeated time range it will be returned as is
            const objDF = parseDataFieldValuesWithRepeatedTimeRange(object?.dataFieldValues);

            return {
                ...object,
                dataFieldValues: convertDateDatafieldValuesToUTC(objDF, layersFiltered?.dataFields),
            };
        });
        const response = await mappingApi.patch<MapObject[]>(`/maps/${mapId}/map_objects/data-fields`, mapObjectsPatch, { signal, suppressErrors: true });
        return response.data;
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        // eslint-disable-next-line no-console
        console.error("Error", error);
        return rejectWithValue(error.response.data);
    }
});

export const uploadGeometry = createAsyncThunk<void, ObjectUpdate[], { getState; state: { mapReducer: MapGlobalState } }>("map/uploadGeometry", async (objects, { getState }) => {
    const {
        mapModule: { mapId },
        creatingObjects,
    } = getState().mapReducer;
    const objectsToUpdateNow = objects.filter((o) => !creatingObjects.includes(o.objectId));
    // Only upload geometries if the object has already been created, if it hasn't the middleware will handle the queue
    if (objectsToUpdateNow.length > 0) {
        await mappingApi.put<void, AxiosResponse<void>, UpdateMultipleMapObjects>(
            `maps/${mapId}/map_objects/geometry`,
            objectsToUpdateNow.reduce(
                (cum, object) => {
                    const add = () => ({
                        id: object.objectId,
                        type: object.geometry.type,
                        coordinates: object.geometry.coordinates,
                        rotation: object.properties.rotation,
                        level: object.properties.level,
                        modeOfTransport: object.properties.modeOfTransport,
                    });
                    switch (object.geometry.type) {
                        case GeometryType.LineString:
                            return { ...cum, lineStrings: [...cum.lineStrings, add()] };
                        case GeometryType.Polygon:
                            return { ...cum, polygons: [...cum.polygons, add()] };
                        case GeometryType.Point:
                            return { ...cum, points: [...cum.points, add()] };
                        default:
                            return cum;
                    }
                },
                { lineStrings: [], polygons: [], points: [] }
            )
        );

        // Update the waypoints if any of the objects are routes
        objectsToUpdateNow.forEach(async (update) => {
            if (update.properties.modeOfTransport != null) {
                await routeWaypointService.putRouteWaypoints(update.objectId, mapId, update.waypoints);
            }
        });
    }
});

export const getProjectDataFields = createAsyncThunk<DataField[], { signal: AbortSignal }, { state: RootState }>("map/getProjectDataFields", async ({ signal }, { getState }) => {
    const { projectId } = getState().auth.user;
    const dataFields = await mappingApi.get<DataField[]>(`/projects/${projectId}/data-fields`, { signal });
    return dataFields.data;
});

export const getSitemaps = createAsyncThunk<Sitemap[], { signal?: AbortSignal }, { state: RootState }>("map/getSitemaps", async ({ signal }, { getState, dispatch }) => {
    if (!getState().auth.subscriptionPlan.functionality.siteMaps) {
        return [];
    }
    const filter: FilterFormat[] = [{ fieldName: "status", operator: IventisFilterOperator.In, value: ["Active"] }];
    const sitemaps = await mappingApi.get<Sitemap[]>(`/sitemaps`, {
        signal,
        params: { filter },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
    dispatch(map.actions.updateSitemapsInView(sitemaps.data));
    return sitemaps.data;
});

export const downloadSitemaps = createAsyncThunk<
    { style: SitemapStyle; asset: Asset }[],
    { sitemaps: Sitemap[]; sitemapConfigs: MapSitemapConfig[]; selectedLevelIndex: number },
    { state: RootState }
>("map/downloadSitemaps", async ({ sitemaps, sitemapConfigs, selectedLevelIndex }, { getState }) => {
    if (!getState().auth.subscriptionPlan.functionality.siteMaps) {
        return [];
    }

    const sitemapVersionLevelStyles: { style: SitemapStyle; asset: Asset }[] = [];
    const visibleSitemaps = sitemapConfigs.filter((sc) => sc.visible !== false);
    await Promise.all(
        visibleSitemaps.map(async (vs) => {
            const sitemap = sitemaps.find((s) => s.id === vs.sitemapId);
            const version = sitemap.versions.find((v) => v.id === vs.versionId) ?? sitemap.versions[sitemap.versions.length - 1];
            if (version != null) {
                const sitemapVersionLevel = version.sitemapVersionLevels.find((l) => l.levelIndex === selectedLevelIndex);
                if (sitemapVersionLevel != null) {
                    // There selected level exists for the selected sitemap version, so download the map
                    // Get the sitemap asset
                    const sitemapAsset = await assetsApi.get<Asset>(`/assets/${sitemapVersionLevel.id}`);
                    // Download the sitemap style
                    let styleUrl = sitemapAsset.data.assetUrl;
                    if (sitemapAsset.data.authoritySignature) {
                        styleUrl = getAssetSignature(styleUrl, sitemapAsset.data.authoritySignature);
                    }
                    const sitemapStyle = await cdnAssets.get<Style>(styleUrl);
                    sitemapVersionLevelStyles.push({ style: { sitemapVersionLevelId: sitemapVersionLevel.id, style: sitemapStyle.data }, asset: sitemapAsset.data });
                }
            }
        })
    );
    return sitemapVersionLevelStyles;
});

export const updateSitemapSignatures = createAsyncThunk<Asset[], { sitemaps: Sitemap[]; sitemapConfigs: MapSitemapConfig[]; selectedLevelIndex: number }, { state: RootState }>(
    "map/updateSitemapSignatures",
    async ({ sitemaps, sitemapConfigs, selectedLevelIndex }, { getState }) => {
        if (!getState().auth.subscriptionPlan.functionality.siteMaps) {
            return [];
        }
        const visibleSitemaps = sitemapConfigs.filter((sc) => sc.visible !== false);
        const assets: Asset[] = [];
        await Promise.all(
            visibleSitemaps.map(async (vs) => {
                const sitemap = sitemaps.find((s) => s.id === vs.sitemapId);
                const version = sitemap.versions.find((v) => v.id === vs.versionId);
                const sitemapVersionLevel = version.sitemapVersionLevels.find((l) => l.levelIndex === selectedLevelIndex);
                if (sitemapVersionLevel != null) {
                    // There selected level exists for the selected sitemap version, so download the map
                    // Get the sitemap asset
                    const sitemapAsset = await assetsApi.get<Asset>(`/assets/${sitemapVersionLevel.id}`);
                    if (sitemapAsset?.data != null) {
                        sitemapAsset.data.updatedAt = parseISO(sitemapAsset.data.updatedAt.toString());
                    }
                    assets.push(sitemapAsset.data);
                }
            })
        );
        return assets;
    }
);

export const updateSavedMapView = createAsyncThunk("Map/updateSavedMapView", async (savedMapView: SavedMapView, { rejectWithValue }) => {
    try {
        const response = await mappingApi.put<SavedMapView>(`/saved_map_views`, savedMapView);
        return response.data;
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        // eslint-disable-next-line no-console
        console.error("Error", error);
        return rejectWithValue(error.response.data);
    }
});

export const createSavedMapView = createAsyncThunk("Map/createSavedMapView", async (savedMapView: SavedMapView, { rejectWithValue }) => {
    try {
        const response = await mappingApi.post<SavedMapView>(`/saved_map_views`, savedMapView);
        return response.data;
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        // eslint-disable-next-line no-console
        console.error("Error", error);
        return rejectWithValue(error.response.data);
    }
});

export const patchMapBackgroundId = createAsyncThunk("Map/patchMapBackgroundId", async (data: { mapId: string; backgroundId: string }, { rejectWithValue }) => {
    try {
        await mappingApi.patch<Map>(`/maps/${data.mapId}`, { backgroundId: data.backgroundId });
        return data.backgroundId;
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        // eslint-disable-next-line no-console
        console.error("Error", error);
        return rejectWithValue(error.response.data);
    }
});

export const patchDefaultSavedMapView = createAsyncThunk("Map/patchDefaultSavedMapView", async (data: { mapId: string; savedMapViewId: string }, { rejectWithValue }) => {
    try {
        await mappingApi.patch<Map>(`/maps/${data.mapId}`, { defaultSavedMapViewId: data.savedMapViewId });
        return data.savedMapViewId;
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        // eslint-disable-next-line no-console
        console.error("Error", error);
        return rejectWithValue(error.response.data);
    }
});

export const getRemoteLayerGeometry = createAsyncThunk<LocalGeoJsonObject[], { layerId: string; signal?: AbortSignal; overwrite?: boolean }, { state: RootState }>(
    "Map/getRemoteLayerGeometry",
    async ({ layerId, signal }, { rejectWithValue, getState }) => {
        try {
            const { mapId } = getState().mapReducer.mapModule;
            const filter = {
                params: {
                    filter: JSON.stringify([{ fieldName: "layerId", operator: IventisFilterOperator.In, value: [layerId] }]),
                    mapId,
                },
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
            };
            const res = await aggregatePagedResponse<MapObject[]>(
                mappingApi,
                `/maps/${getState().mapReducer.mapModule.mapId}/map_objects/filter`,
                { ...filter, signal },
                async (cum, newValue) => (cum ?? []).concat(newValue)
            );
            // Get the waypoints for the map objects if they exist
            const waypoints = await Promise.all(
                res.map<Promise<RouteWaypoint[]>>(async (object) => {
                    let waypoints: RouteWaypoint[] | null = null;
                    if ((object.modeOfTransport ?? object.geoJsonFeature?.properties?.modeOfTransport) != null) {
                        waypoints = await routeWaypointService.getRouteWaypoints(object.id, mapId);
                    }
                    return waypoints;
                })
            );
            return res?.map?.((object, index) => convertMapObjectToLocalGeoJson(object, waypoints[index]));
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateMapSidebarOrder = createAsyncThunk("Map/updateLayersSidebarOrder", async (data: { layers: SidebarOrderUpdate[]; mapId: string }, { rejectWithValue }) => {
    try {
        const request = await mappingApi.patch(`maps/${data.mapId}/layers`, data.layers);
        return request;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const patchMapComment = createAsyncThunk<void, GeoJSON.Feature<GeoJSON.Point, MapObjectProperties>, { state: RootState }>(
    "Map/patchMapComment",
    async (payload, { getState }) => {
        const { mapId } = getState().mapReducer.mapModule;
        await mappingApi.patch<Partial<MapComment>>(`/maps/${mapId}/comments/${payload.properties.id}`, { geometry: payload.geometry, level: payload.properties.level });
    }
);

export const getMapCommentFeature = createAsyncThunk<
    GeoJSON.Feature<GeoJSON.Point, MapCommentProperties>,
    { commentId: string; focus?: boolean; panTo?: boolean; existsOnServer?: boolean },
    { state: RootState }
>("Map/getMapComment", async ({ commentId, focus }, { getState }) => {
    const { mapId, geoJSON } = getState().mapReducer.mapModule;
    const existingComment = geoJSON.value[commentsLayerId]?.find((c) => c.objectId === commentId);
    if (existingComment != null) {
        return {
            ...existingComment.feature,
            properties: { ...existingComment.feature.properties, imageId: focus ? DEFAULT_COMMENT_ICON_VALUE : existingComment.feature.properties.userId },
        } as GeoJSON.Feature<GeoJSON.Point, MapCommentProperties>;
    }
    const response = await mappingApi.get<MapComment>(`maps/${mapId}/comments/${commentId}`);
    const feature = {
        geometry: response.data.geometry,
        type: "Feature",
        properties: {
            id: response.data.id,
            layerid: commentsLayerId,
            level: response.data.level,
            userId: response.data.userId,
            imageId:
                // Ensure the selection hasn't changed since the request was made
                focus && getState().mapReducer.mapModule.commentsSelected.value.some((c) => c.commentId === response.data.id) ? DEFAULT_COMMENT_ICON_VALUE : response.data.userId,
            rotation: undefined,
            order: focus ? 1 : 0,
        },
    } as const;
    return feature;
});
