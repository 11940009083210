import { LocalGeoJson } from "@iventis/map-types";

/**
 * Merges two LocalGeoJson objects into a single combined object.
 */
export function mergeGeojson(existingLocalGeoJson: LocalGeoJson, newLocalGeoJson: LocalGeoJson) {
    if (newLocalGeoJson == null) {
        return existingLocalGeoJson;
    }

    const updatedLocalGeoJson = existingLocalGeoJson != null ? { ...existingLocalGeoJson } : {};
    Object.entries(newLocalGeoJson).forEach(([layerId, newObjects]) => {
        const existingObjects = updatedLocalGeoJson[layerId] ?? [];
        updatedLocalGeoJson[layerId] = [...existingObjects, ...newObjects];
    });
    return updatedLocalGeoJson;
}
