/* eslint-disable camelcase */
export const subscriptionPlan = {
    plan_details: {
        frequency: {
            annually: "Annually",
            monthly: "Monthly",
            title: "Pay annually and save 10%",
        },
        choose_currency: "Choose your currency",
        includes: "What's included:",
        everything_in: "Everything in {{ plan }}, plus:",
        per_user: "per user / per month",
        switch_to: "Switch to {{ plan }}",
        upgrade_to: "Upgrade to {{ plan }}",
        manage_plan: "Manage plan",
        call_us: "Call us",
        contact_us: "Contact us",
        title: {
            part_1: "Choose the perfect plan for you and your",
            part_2: "Team",
        },
        subtitle: {
            part_1: "Compare our plans and features on our",
            part_2: "pricing page",
            part_3: "or",
        },
        plans: {
            professional: {
                name: "professional",
                prev_plan: "",
                features: {
                    1: "Unlimited plans for your event",
                    2: "Access to an extensive library of layer templates",
                    3: "3D models to accurately visualise plans",
                    4: "Attributes to capture data including dates and costs",
                    5: "Shared workspace for your team",
                    6: "Customisable and flexible layers and icons",
                    7: "Analysis tools including crowd capacity calculator",
                    8: "Professional PDF exports",
                    9: "CAD integration and unlimited updates",
                    10: "Onboarding support",
                    11: "Unlimited viewers!",
                },
            },
            premium: {
                name: "premium",
                prev_plan: "professional",
                features: {
                    1: "Capture and visualise plans by time and date",
                    2: "Collaboration tools incl. comments, tagging, threads",
                    3: "Combine plans from different users and teams",
                    4: "Analyse quantities and costs across your project",
                    5: "Geospatial data export in GIS formats",
                    6: "CAD importing service",
                    7: "Bespoke onboarding support",
                    8: "Unlimited viewers!",
                },
            },
            enterprise: {
                name: "enterprise",
                prev_plan: "premium",
                features: {
                    1: "Bespoke onboarding and account management",
                    2: "Visualise plans using gaming quality graphics",
                    3: "Plan using Google 3D city maps",
                    4: "Publish branded maps online",
                    5: "Expert support to create 3D models, icons and templates",
                    6: "SSO for added security",
                    7: "24/7 support",
                },
            },
        },
    },
} as const;

export const subscriptionPlan2 = {
    limits: {
        overlay: {
            title: {
                pdfExports: "Export plan to PDF",
                importingLayers: "Import layers",
                teamWorkspace: "Team Workspace",
                team: "Create teams",
                maximumUsers: "Add users",
                siteMaps: "Add sitemaps",
                FlowRate: "Analysis tools",
                Capacity: "Analysis tools",
                layer3DTypes: "Create 3D layer",
                iconAndModelUploads: "Upload 3D/icon components",
                attributeDataExport: "Export to Excel",
                sharedLinks: "Generate link",
                imagesOnModels: "Custom Images on Models",
            },
            content: {
                text1: {
                    pdfExports: "You currently do not have access to export the plan to PDF. Upgrade to work on plans collaboratively with other team members.",
                    importingLayers: "You currently do not have access to import layers from other plans. Upgrade to work on plans collaboratively with other team members.",
                    teamWorkspace: "You currently do not have access to the team workspace. Upgrade to work on plans collaboratively with other team members.",
                    team: "You currently do not have access to create teams in your current plan. Upgrade to work on plans collaboratively with other team members.",
                    maximumUsers: "You currently do not have access to add more users. Upgrade to work on plans collaboratively with other team members.",
                    maxTeamMaps: "Oh no! It looks like you have run out of plans for your plan. Upgrade to work on plans collaboratively with other team members.",
                    siteMaps: "You currently do not have access to upload sitemaps in your current plan. Upgrade to integrate CAD drawings into your plans.",
                    FlowRate:
                        "You currently do not have access to the flow rate analysis tool in your current plan. Upgrade to work on plans collaboratively with other team members.",
                    Capacity:
                        "You currently do not have access to the capacity analysis tool in your current plan. Upgrade to work on plans collaboratively with other team members.",
                    customLogo: "Upgrade to add your own logo and branding",
                    layer3DTypes: "You currently do not have access to create 3D layers in your current plan. Upgrade to work on plans collaboratively with other team members.",
                    iconAndModelUploads:
                        "You currently do not have access to upload 3D/icon components in your current plan. Upgrade to work on plans collaboratively with other team members.",
                    customLayer: "Your current plan does not allow you to create custom layers. Upgrade to create more plans collaboratively with other team members.",
                    attributeDataExport: "You currently do not have access to export to Excel. Upgrade to work on plans collaboratively with other team members.",
                    sharedLinks: "You currently do not have access to the share the map. Upgrade to work on maps collaboratively with other team members.",
                    sso: "Upgrade your plan to configure Sso",
                    imagesOnModels: "You currently do not have access to upload custom images onto your models. Upgrade to work on plans collaboratively with other team members.",
                },
                text2: "Here are some of the benefits of an upgrade:",
                professional: {
                    1: "Shared workspace for your team",
                    2: "Customisable and flexible layers and icons",
                    3: "CAD integration and PDF exports",
                },
            },
        },
    },
} as const;

export const subscriptionPlan3 = {
    cancellation: {
        confirmationTitle: "Cancel subscription",
        confirmationBodyOne: "Are you sure you want to cancel your subscription",
        confirmationBodyTwo: "You will no longer have acecess to your account and data after the subscription expires",
        reasonHeadingOne: "We are sorry to see you leave.",
        reasonHeadingTwo: "We'd love to get your input on how to make Iventis better.",
        reasonSubheadingOne: "What is the main reason for cancelling?",
        reasonSubheadingTwo: "Please provide any additional feedback",
        reasonOne: "I am not getting complete use of Iventis",
        reasonTwo: "I am finding it difficult to use",
        reasonThree: "It does not have a feature I am looking for",
        reasonFour: "It is too expensive",
        reasonFive: "I have deciced to switch to another product",
        reasonSix: "Other",
        addComment: "Add a comment",
        cancelSubscription: "Cancel subscription",
        reactivate: "Reactivate your subscription",
        success: "Your subscription has been cancelled and will expire on",
        error: "There has been a problem cancelling your subscription. Please contact support.",
        successReactivate: "You're project has been reactivated",
        errorReactivate: "There has been a problem reactivating your subscription. Please contact support.",
        contactUs: "Contact support",
        nonAutomatedCancel: "Please contact support to cancel your subscription",
        nonAutomatedReactivate: "Please contact support to reactivate your subscription",
        cancelsOn: "Cancels on",
        keepSubscription: "Keep subscription",
    },
    limits: {
        limitedResource: {
            IndividualMap: "plans",
            TeamMap: "plans",
            User: "users",
        },
        out_of: "{{ current }} out of {{ maximum }} active {{ resources }}",
        users: {
            limitReached: {
                title: "User limit reached",
                content: "You have reached the maximum number of users for this project. To add more users please",
            },
            limitRemaining: {
                title: "1 user remaining",
                content: "You are about to reach the maximum number of users for this project. To add more users",
            },
        },
        custom_layer: {
            title: "Upgrade to customise your layer",
            content: "Upgrade to our professional plan for limitless customisation or contact us at:",
        },
    },
} as const;
