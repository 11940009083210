import { connection, webSocketMessage, SocketStatus } from "./connection.slice";

const socketMiddleware = () => {
    let socket: WebSocket = null;
    let interval = null;
    let reconnectAttempts = 0;
    let attemptingToReconnect = false;
    let host = "";
    let store = null;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const messageQueue: any[] = [];

    const isConnected = () => socket != null && socket.readyState === WebSocket.OPEN;
    const isConnecting = () => socket != null && socket.readyState === WebSocket.CONNECTING;
    const isDisconnected = () => socket == null || socket.readyState === WebSocket.CLOSED;
    const willBeDisconnected = () => isDisconnected() || socket.readyState === WebSocket.CLOSING;

    const connect = () => {
        if (isConnected() || isConnecting()) {
            return;
        }

        // Disconnect to clear any existing connections
        disconnect();

        // connect to the remote host
        socket = new WebSocket(host);

        // websocket handlers
        socket.onmessage = onMessage();
        socket.onclose = onClose();
        socket.onopen = onOpen();
        socket.onerror = onError();
    };

    const disconnect = () => {
        if (!willBeDisconnected() && isConnected()) {
            socket.close();
            socket = null;
        }
        if (interval != null) {
            clearInterval(interval);
            interval = null;
        }
    };

    const sendMessage = (message) => {
        if (isConnected()) {
            socket.send(JSON.stringify(message));
        } else {
            messageQueue.push(message);
        }
    };

    const checkForReconnectInFuture = () => {
        reconnectAttempts += 1;
        let reconnectInterval = reconnectAttempts * 1000;
        if (reconnectInterval > 10000) {
            reconnectInterval = 10000;
        }
        setTimeout(() => reconnect(), reconnectInterval);
    };

    const reconnect = () => {
        if (willBeDisconnected()) {
            if (reconnectAttempts < 20) {
                // Reconnect attempts should be made quickly, and wait longer each time, up to a maximum of 10 seconds
                checkForReconnectInFuture();
                connect();
            } else {
                disconnect();
            }
        } else if (!isConnected()) {
            checkForReconnectInFuture();
        } else {
            store.dispatch(connection.actions.updateWsConnection(SocketStatus.CLOSED));
            clearInterval(interval);
            interval = null;
            socket = null;
        }
    };

    const processQueue = () => {
        while (messageQueue.length > 0) {
            const message = messageQueue.shift();
            socket.send(JSON.stringify(message));
        }
    };

    const onOpen = () => () => {
        store.dispatch(connection.actions.updateWsConnection(SocketStatus.OPENED));
        reconnectAttempts = 0;
        attemptingToReconnect = false;
        processQueue();
        // Send a keep alive message every 5 minutes
        interval = setInterval(() => {
            sendMessage({
                eventName: "COMMS_CONNECTION_KEPT_ALIVE",
            });
        }, 360000);
    };

    const onClose = () => () => {
        if (!attemptingToReconnect) {
            store.dispatch(connection.actions.updateWsConnection(SocketStatus.CONNECTING));
            reconnect();
            attemptingToReconnect = true;
        }
    };

    const onMessage = () => (msg) => {
        const data = JSON.parse(msg.data);
        webSocketMessage.next(data);
    };

    const onError = () => () => {
        store.dispatch(connection.actions.updateWsConnection(SocketStatus.ERRORED));
    };

    // the middleware part of this function
    return (_store) => (next) => (action) => {
        switch (action.type) {
            case "WS_CONNECT":
                store = _store;
                host = action.host;
                connect();
                break;
            case "NEW_MESSAGE":
                sendMessage(action.payload.message);
                break;
            case "WS_DISCONNECT":
                disconnect();
                // Clear the message queue in case we are disconnecting because we are logging out
                // and a new user is logging in, so we do not want to send the previous messages
                messageQueue.splice(0, messageQueue.length);
                break;
            default:
                break;
        }
        return next(action);
    };
};

export default socketMiddleware();
